import { useEffect, useState } from "react";
import { SavingEvent } from "devextreme/ui/data_grid";

import { IUseNetCash } from "./IUseNetCash";
import { NetCash } from "../Models/NetCash";
import { Fund } from "../Models/Fund";

const useNetCash = ({ api }: IUseNetCash) => {
	const [data, setData] = useState<any>([]);
	const [funds, setFunds] = useState<Fund[]>([]);
	const [numChanges, setNumChanges] = useState<number>(0);
	const [responses, setResponses] = useState<boolean[]>([]);

	useEffect(() => {
		getFunds();
	}, []);

	useEffect(() => {
		if (funds.length > 0) getNetCash();
	}, [funds]);

	useEffect(() => {
		if (numChanges > 0 && responses.length === numChanges) {
			getNetCash();
			setNumChanges(0);
			setResponses([]);
		}
	}, [numChanges, responses]);

	const getFunds = () => api.getFunds().then((response: any) => setFunds(response));

	const getNetCash = () => {
		api.getNetCash().then((response: any) => {
			const netCashes = response.map((netCash: NetCash) => {
				const fund = funds.find((fund: Fund) => 
					fund.idPeip === netCash.idPeip && fund.idVehicle === netCash.idVehicle
				);

				return { ...netCash, fund: fund?.name };
			})
			setData(netCashes);
		});
	};

	const saveNetCash = (data: NetCash) => api.saveNetCash(data).then((response: boolean) => {
		responses.push(response);
	});

	const updateNetCash = (data: NetCash) => api.updateNetCash(data).then((response: boolean) => {
		responses.push(response);
	});

	const deleteNetCash = (data: NetCash) => api.deleteNetCash(data).then((response: boolean) => {
		responses.push(response);
	});

	const handleSaveChanges = (e: SavingEvent) => {
		e.changes.map((change: any) => {
			if (change.type === 'remove') {
				const netCash = data.filter((netCash: NetCash) => netCash.id === change.key);
				deleteNetCash(netCash[0]);
			}
			if (change.type === 'insert') {
				const fund = funds.filter((fund: Fund) => fund.name === change.data.fund);
				saveNetCash({ ...change.data, idPeip: fund[0].idPeip, idVehicle: fund[0].idVehicle });
			}
			if (change.type === 'update') {
				const netCash = data.filter((netCash: NetCash) => netCash.id === change.key);
				const fund = funds.filter((fund: Fund) => fund.name === netCash[0].fund);
				updateNetCash({ ...netCash[0], ...change.data, idPeip: fund[0].idPeip, idVehicle: fund[0].idVehicle });
			}

			return change;
		});
	};

	return {
		funds,
		data,
		handleSaveChanges
	}
};

export default useNetCash;